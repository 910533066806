.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bg-image-slide {
  position: relative;
  overflow: hidden;
}


.bg-image-slide::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 1;
}

/* Ensure container or other content is above the pseudo-element */
.bg-image-slide > * {
  position: relative;
  z-index: 2;
}


.gradient-text {
  background: linear-gradient(to right, #00f, rgb(203, 76, 41), #00f); /* Example gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.zoom-effect {
  transition: transform 0.3s ease;
}

.zoom-effect:hover {
  transform: scale(1.1); /* Adjust the scale as needed */
}

.zoom-effect-icon {
  transition: transform 0.3s ease;
}

.zoom-effect-icon:hover {
  transform: scale(1.2); /* Adjust the scale as needed */
}

@keyframes gradient-flow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.animate-gradient-flow {
  background-size: 200% 200%;
  animation: gradient-flow 4s linear infinite;
}

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}